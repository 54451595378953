import React from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/common/button';
import { Content } from '../components/common/content';
import { resolve } from '../helpers/urls';
import './css/404.scss';

const bem = BEMHelper({
  name: 'error-page',
});

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Content {...bem()} useOwnSpacing>
      <h1 {...bem('headline')}>{t('404 Error')}</h1>
      <p {...bem('text')}>
        {t('We’re sorry but this page cannot be found.')}
      </p>
      <Button variant="secondary" to={resolve('home')} title={t('Go to homepage')} {...bem('link')}>{t('Go to homepage')}</Button>
    </Content>
  );
};

export default NotFoundPage;
